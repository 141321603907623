import React from "react";
import {ChakraProvider, Container, Link, Text, VStack} from "@chakra-ui/react";
import {isRouteErrorResponse, useRouteError} from "react-router-dom";

export interface ErrorPageProps {

}

const ErrorPage: React.FC<ErrorPageProps> = (props) => {
    const error = useRouteError()
    console.error(error)

    return (
        <ChakraProvider>
            <Container colorScheme={"red"}>
                <VStack>
                    <Text>
                        Oops! Something went wrong. For more details, see below:
                    </Text>
                    <Text color={"red.500"}>
                        {isRouteErrorResponse(error) ? error?.error?.message : "Unknown error"}
                    </Text>
                    <Link href={"/"}>Return to home</Link>
                </VStack>
            </Container>
        </ChakraProvider>
    )
}

export default ErrorPage