import {ColorModeScript} from "@chakra-ui/react"
import * as React from "react"
import * as ReactDOM from "react-dom/client"
import {FrontPage} from "pages/FrontPage"
import reportWebVitals from "reportWebVitals"
import * as serviceWorker from "serviceWorker"
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ErrorPage from "components/ErrorPage";
import Queue from "pages/Queue";
import {QueryClient, QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools";
import RootWrapper from "components/RootWrapper";


const container = document.getElementById("root")
if (!container) throw new Error('Failed to find the root element');

const router = createBrowserRouter([
    {
        path: "/",
        element: <FrontPage/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/lobby",
        element: <Queue/>
    }
])
const root = ReactDOM.createRoot(container)
const queryClient = new QueryClient()

root.render(
    <React.StrictMode>
        <ColorModeScript/>
        <QueryClientProvider client={queryClient}>
            <RootWrapper>
                <RouterProvider router={router}/>
                <ReactQueryDevtools initialIsOpen={false}/>
            </RootWrapper>
        </QueryClientProvider>
    </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

