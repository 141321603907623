import {ColorModeSwitcher} from "ColorModeSwitcher";
import {ChakraProvider} from "@chakra-ui/react";
import React, {PropsWithChildren} from "react";

const RootWrapper: React.FC<PropsWithChildren> = (props) => {

    return (
        <>
            <ChakraProvider>
                <ColorModeSwitcher/>
                {props.children}
            </ChakraProvider>
        </>
    )
}

export default RootWrapper