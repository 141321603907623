import {
    Button,
    Center,
    ChakraProvider,
    Container,
    Image,
    Spinner,
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    theme,
    Tr
} from "@chakra-ui/react";
import {useState} from "react";
import axios from "axios";
import {useQuery} from "react-query";

export interface SteamUser {
    SteamID: string,
    CommunityVisibleState: number,
    ProfileState: number,
    PersonaName: string,
    ProfileURL: string,
    Avatar: string,
    AvatarMedium: string,
    AvatarFull: string,
    AvatarHash: string,
    PersonaState: number,
    RealName: string,
    PrimaryClanId: string,
    TimeCreated: number
    PersonaStateFlags: number,
    LocCountryCode: string,
    LocStateCode: string,
    LocCityId: number
}

const Queue = () => {

    const [data, setData] = useState<SteamUser[]>()
    const {isSuccess} = useQuery(
        "fetchLobby",
        async () => await axios.get("https://api.inhouse.vsahav.org/lobby"),
        {
            onSuccess: result => {
                setData(result.data)
            },
            staleTime: 30000,
            refetchInterval: 30000
        }
    )


    const loadingOrTable =
        (
            !isSuccess ? <Center height={"100vh"}><Spinner alignSelf={"center"} justifySelf={"center"} size={"xl"}/></Center> :
                <TableContainer>
                    <Table variant={'simple'}>
                        <TableCaption>Lobby</TableCaption>
                        <Thead>
                            <Tr>
                                <Th>Avatar</Th>
                                <Th>Name</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data?.map(user => {
                                return (
                                    <Tr key={user.SteamID}>
                                        <Td><Image src={user.Avatar}/></Td>
                                        <Td>{user.PersonaName}</Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
        )

    return (
        <ChakraProvider theme={theme}>
            <Container colorScheme={'red'} justifyContent={"center"} height={"100vh"}>
                {loadingOrTable}

            </Container>
        </ChakraProvider>

    )
}

export default Queue