import * as React from "react"
import {Box, ChakraProvider, Container, Grid, Link, theme, VStack,} from "@chakra-ui/react"

export const FrontPage = () => (
    <ChakraProvider theme={theme}>
        <Container colorScheme={"red"}>
            <Box textAlign="center" fontSize="xl">
                <Grid minH="100vh" p={3}>
                    <VStack spacing={8}>
                        <Link
                            href='https://api.inhouse.vsahav.org/signin'
                        >
                            Signup for inhouse fun!
                        </Link>
                    </VStack>
                </Grid>
            </Box>
        </Container>
    </ChakraProvider>
)
